<template>
  <div class="c-register">
    <Header />
    <Breadcrumb :title="'Register'" :link="'register'" />
    <div class="register-bg">
    <div class="container c-register__form">
      <el-steps
        :active="activeStepBar"
        class="c-register__tabs"
        finish-status="success"
      >
        <el-step title="User Information"></el-step>
        <el-step title="Personal Record"></el-step>
        <el-step title="Appearance and Life style"></el-step>
        <el-step title="Education and Profession"></el-step>
        <el-step title="Family Details"></el-step>
      </el-steps>
      <UserInformationForm
        @data:create="onCreate"
        class="c-add-user__form"
        v-if="tab === 1"
      />

      <PersonalRecordForm
        @data:create="onCreate"
        class="c-add-user__personal-record-form"
        v-if="tab === 2"
      />

      <PhysicalAppAndLifeStyleForm
        @data:create="onCreate"
        class="c-register__physical-app"
        v-if="tab === 3"
      />

      <EducationAndProfessionForm
        @data:create="onCreate"
        class="c-register__education-profession"
        v-if="tab === 4"
      />

      <FamilyDetailForm
        @user:create="onUserCreate"
        class="c-register__family-detail"
        v-if="tab === 5"
      />
    </div>
</div>
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import Breadcrumb from '@/views/user/_components/Breadcrumb'
import UserInformationForm from './_components/UserInformationForm'
import PersonalRecordForm from './_components/PersonalRecordForm'
import PhysicalAppAndLifeStyleForm from './_components/PhysicalAppAndLifeStyleForm'
import EducationAndProfessionForm from './_components/EducationAndProfessionForm'
import FamilyDetailForm from './_components/FamilyDetailForm'

export default {
  components: {
    UserInformationForm,
    PhysicalAppAndLifeStyleForm,
    PersonalRecordForm,
    EducationAndProfessionForm,
    FamilyDetailForm,
    Header,
    Footer,
    Breadcrumb
  },

  data () {
    return {
      activeStepBar: 0,
      tab: 1
    }
  },

  methods: {
    onCreate () {
      this.activeStepBar++
      this.tab++
    },

    onUserCreate () {
      this.$message({
        message: 'User created successfully. Please check email and verify email to login',
        type: 'success'
      })

      this.$router.push({ name: 'frontend' })
    }
  },

  name: 'Register'
}
</script>
<style lang="scss">
.register-bg{
  margin: 0;
  background: #f3f2ef;
  padding: 30px 0 30px 0;
}

.el-step__icon.is-text {
  color: #fff;
  border-radius: 50%;
  border: 2px solid;
  border-color: #fff;
  color: #fff;
  background: #f56c6c;
  width: 40px;
  height: 40px;
}

.el-step.is-horizontal .el-step__line {
  height: 2px;
  top: 20px;
  left: 0;
  right: 0;
}

.el-step__title.is-process {
  font-weight: 700;
  color: #013791;
}

.el-form{
  padding-top: 20px;
}

.el-button--primary {
  color: #FFF;
  background-color: #003895;
  border-color: #003895;
}

.el-input__inner{
  background-color: #eee;
}
.el-form-item__label{
  font-weight: 600;
}

.c-register {
  &__form {
    padding: 40px 40px;
    background: #ffffff;
    border-radius: 5px;
  }

  &__tabs {
    margin-bottom: 1rem;
  }
}
</style>
