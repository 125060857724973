<template>
  <div class="c-user-form">
    <el-form
      ref="form"
      :model="state.userData"
      v-loading="state.loading"
      label-width="120px"
    >
      <el-form-item label="Name">
        <el-input
          v-model="state.userData.name"
        >
        </el-input>
        <span
          class="c-user-form__error"
          v-if="v$.userData.name.$error"
        >
          {{ v$.userData.name.$errors[0].$message }}
        </span>
      </el-form-item>

      <el-form-item label="Email">
        <el-input
          v-model="state.userData.email"
          type="email"
        >
        </el-input>
        <span
          class="c-user-form__error"
          v-if="v$.userData.email.$error"
        >
          {{ v$.userData.email.$errors[0].$message }}
        </span>
      </el-form-item>

      <el-form-item label="Phone">
        <el-input
          class="c-user-form__phone"
          v-model="state.userData.phone"
          type="number"
          id="telephone"
        >
        </el-input>
        <span
          class="c-user-form__error"
          v-if="v$.userData.phone.$error"
        >
          {{ v$.userData.phone.$errors[0].$message }}
        </span>
      </el-form-item>

      <el-form-item label="Password">
        <el-input
          v-model="state.userData.password"
          type="password"
        >
        </el-input>
      </el-form-item>

      <el-form-item>
        <el-checkbox label="I'm above 18 yrs of age" name="above_18" v-model="state.userData.above18"></el-checkbox>

        <span
          class="c-user-form__error"
          v-if="v$.userData.above18.$error"
        >
          {{ v$.userData.above18.$errors[0].$message }}
        </span>
      </el-form-item>
      <el-form-item>
        <el-button
          @click="onSubmit"
          type="primary"
          round
        >
          Submit
        </el-button>
        <el-button round>Cancel</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import 'intl-tel-input/build/css/intlTelInput.css'
import 'intl-tel-input/build/js/intlTelInput.js'
import intlTelInput from 'intl-tel-input'

import useValidate from '@vuelidate/core'
import {
  required,
  helpers,
  email,
  numeric,
  sameAs
} from '@vuelidate/validators'
import { reactive, computed } from 'vue'

import userMixin from '@/mixins/user'

export default {
  setup () {
    const state = reactive({
      loading: false,
      userData: {
        name: '',
        email: '',
        phone: '',
        password: '',
        above18: false
      }
    })

    const rules = computed(() => {
      return {
        userData: {
          name: { required: helpers.withMessage('Name field is required', required) },
          email: { required: helpers.withMessage('Email field is required', required), email },
          phone: { required: helpers.withMessage('Phone field is required', required), numeric },
          above18: { required: helpers.withMessage('This field is required', sameAs(true)) }
        }
      }
    })

    const v$ = useValidate(rules, state)

    return {
      state,
      v$
    }
  },

  methods: {
    onSubmit () {
      this.v$.$validate()
      if (!this.v$.$error) {
        this.state.loading = true

        this.createUserInformation(this.state.userData).then((res) => {
          this.$message({
            message: 'User Information created successfully.Please continue further to complete profile or you can verify email and complete profile from dashboard.',
            type: 'success'
          })

          this.state.loading = false

          this.$emit('data:create')
        }).catch((error) => {
          this.state.loading = false

          this.$message({
            message: error.response.data.error ? 'Invalid data' : error.response.data.message,
            type: 'warning'
          })
        })
      }
    }
  },

  mixins: [userMixin],

  mounted () {
    const input = document.querySelector('#telephone')
    const iti = intlTelInput(input, {
      // any initialisation options go here
      preferredCountries: ['np', 'us'],
      separateDialCode: true,
      hiddenInput: 'full'
    })

    const number = iti.getNumber()

    console.log(number)
  },

  name: 'UserForm'
}
</script>

<style lang="scss">
.c-user-form {
  &__error {
    color: darkred;
  }

  &__phone {
    .iti {
      width: 100%;
    }
  }
}
</style>
